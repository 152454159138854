import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertType, AlertsContext, IAlert } from "../../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../../services/Constants/Constants";
import { useAuth } from "../../../useAuth";
import { QueryCacheKey } from "../../QueryCacheKey";

interface IResponse {}

const getMutationQuery = (token: string | null) => {
    const request = async (jobName: string): Promise<IResponse> => {
        const config = getAxiosRequestConfig(token);
        const request = axios.delete(`${Constants.ApiRoot}/admin/k8s-job/${jobName}`, config);

        const { data } = await request;
        return data;
    };
    return request;
};

export const useDeleteK8sJob = (onSuccess?: (response: IResponse) => void) => {
    const { authToken } = useAuth();
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(authToken), {
        onMutate: () => {},
        onSuccess: (data) => {
            queryClient.invalidateQueries([QueryCacheKey.K8sJob], { exact: false });
            onSuccess?.(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
        },
    });
    return { mutation };
};
