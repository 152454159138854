import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../../services/Constants/Constants";
import { useAuth } from "../../../useAuth";
import { QueryCacheKey } from "../../QueryCacheKey";

interface IJobMetadata {
    readonly annotations: {
        [key: string]: string;
    };
    readonly creationTimestamp: string;
    readonly generation: number;
    readonly labels: {
        [key: string]: string;
    };
    readonly name: string;
    readonly namespace: string;
    readonly resourceVersion: string;
    readonly uid: string;
}

interface IJobCondition {
    readonly lastProbeTime: string;
    readonly lastTransitionTime: string;
    readonly status: string;
    readonly type: string;
}

interface IJobStatus {
    readonly completionTime: string;
    readonly conditions: IJobCondition[];
    readonly ready: number;
    readonly startTime: string;
    readonly succeeded: number;
    readonly uncountedTerminatedPods: { [key: string]: any };
}

interface IK8sJob {
    readonly metadata: IJobMetadata;
    readonly status: IJobStatus;
}

interface IResponse {
    readonly k8sJobs: Array<IK8sJob>;
}

const getK8sJobsList = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/admin/k8s-job/list`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data.k8sJobs.map((job) => ({
        name: job.metadata.name,
        completionTime: job.status.completionTime,
        startTime: job.status.startTime,
    }));
};

export const useK8sJobList = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.K8sJob], () => getK8sJobsList(config));
};
