import { Button, Table } from "antd";
import * as React from "react";
import { useDeleteK8sJob } from "../../../../hooks/api/admin/k8sJob/useDeleteK8sJob";
import { useK8sJobList } from "../../../../hooks/api/admin/k8sJob/useK8sJobList";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { AdminNavigation } from "../AdminNavigation";

interface K8sJob {
    readonly name: string;
    readonly startTime: string;
    readonly completionTime: string;
}

export const K8sJobsPage: React.FC = () => {
    const k8sJobList = useK8sJobList();

    const { mutation: deleteK8sJob } = useDeleteK8sJob();

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Started Time",
            dataIndex: "startTime",
            key: "startTime",
        },
        {
            title: "Completion Time",
            dataIndex: "completionTime",
            key: "completionTime",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_text: string, k8sJob: K8sJob) => (
                <Button
                    type="primary"
                    onClick={() => {
                        deleteK8sJob.mutate(k8sJob.name);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];

    const dataSource: Array<K8sJob> = k8sJobList.data ?? [];

    if (k8sJobList.isLoading || deleteK8sJob.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <AdminNavigation />

            <Table columns={columns} dataSource={dataSource} rowKey="_id" />
        </>
    );
};
