import React from "react";
import { WithAuth } from "../../components/molecules/Auth/WithAuth";
import { K8sJobsPage } from "../../components/pages/AdminPage/K8sJobsPage /K8sJobsPage ";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <K8sJobsPage />
        </WithAuth>
    );
};

export default Page;
